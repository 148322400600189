import React, { useState, useEffect } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { MdDelete, MdModeEdit } from "react-icons/md";
import useStore from "../../store";
import {
  getAllUser,
  updateUser,
  createUser,
  deleteUser,
} from "../../apis/api-endpoint";
import { toast } from "react-hot-toast";

const AddNewUser = () => {
  const { displayLoader, hideLoader } = useStore();
  const [eyeOpen, setEyeOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState();
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [editpasswordError, setEditPasswordError] = useState(false);

  const handleAllUser = async () => {
    displayLoader();
    try {
      const response = await getAllUser();

      if (response?.status) {
        setUserData(response?.data?.users);
        hideLoader();
      } else {
        hideLoader();
        // Handle the case when response is undefined or falsy
        // toast.error("Failed to fetch funds data.");
      }
    } catch (error) {
      hideLoader();
      // Handle the error here, for example:
      toast.error("An error occurred while fetching all users data.");
    }
  };

  useEffect(() => {
    handleAllUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstName !== "") {
      setFirstNameError(false);
    }
    if (lastName !== "") {
      setLastNameError(false);
    }
    if (email !== "") {
      setEmailError(false);
    }
    if (password !== "") {
      setPasswordError(false);
    }
  }, [firstName, lastName, email, password]);

  const handleEdit = (obj: any) => {
    setIsEdit(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setFirstName(obj?.firstName);
    setLastName(obj?.lastName);
    setEmail(obj?.email);
    setUserId(obj?.id);
    setEditPasswordError(true);
  };

  const handleError = () => {
    if (firstName === "") {
      setFirstNameError(true);
    } else if (lastName === "") {
      setLastNameError(true);
    } else if (email === "") {
      setEmailError(true);
    } else if (password === "") {
      setPasswordError(true);
    }
  };

  const handleCreateUser = async () => {
    if (!isEdit) {
      setEditPasswordError(false);
    }
    handleError();
    const payload = {
      firstName,
      lastName,
      email,
      password,
    };
    if (
      email !== "" &&
      password !== "" &&
      firstName !== "" &&
      lastName !== ""
    ) {
      displayLoader();
      const response: any = await createUser(payload);
      if (response instanceof Error) {
        //@ts-ignore
        toast.error(response?.response?.data?.errors);
        hideLoader();
      } else if (response?.data?.status) {
        hideLoader();
        handleAllUser();
        toast.success(response?.data?.msg);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
      } else {
        hideLoader();
      }
    }
  };

  const handleUpdate = async () => {
    handleError();
    const payload = {
      firstName,
      lastName,
      email,
      password,
      userId,
    };
    if (email !== "" && firstName !== "" && lastName !== "") {
      displayLoader();
      const response: any = await updateUser(payload);
      if (response instanceof Error) {
        //@ts-ignore
        toast.error(response?.response?.data?.errors);
        hideLoader();
      } else if (response?.data?.status) {
        hideLoader();
        setIsEdit(false);
        toast.success(response?.data?.msg);
        handleAllUser();
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
      } else {
        hideLoader();
      }
    }
  };

  const handleDeleteUser = async (id: any) => {
    if (id) {
      displayLoader();
      const response: any = await deleteUser(id);
      if (response instanceof Error) {
        //@ts-ignore
        toast.error(response?.response?.data?.errors);
        hideLoader();
      } else if (response?.data?.status) {
        hideLoader();
        toast.success(response?.data?.msg);
        handleAllUser();
        setIsEdit(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
      } else {
        hideLoader();
      }
    }
  };

  return (
    <div>
      <div className="file-box">
        <p className="file-text">
          {isEdit ? "Update User" : "Create New User"}
        </p>
        <div className="create-user">
          <div>
            <input
              type="text"
              placeholder="Enter first name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="file-input"
            />
            {firstNameError && <p className="user-required-text">Required</p>}
          </div>
          <div>
            <input
              type="text"
              placeholder="Enter last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="file-input"
            />
            {lastNameError && <p className="user-required-text">Required</p>}
          </div>
        </div>
        <div className="create-user">
          <div>
            <input
              type="text"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="file-input"
            />
            {emailError && <p className="user-required-text">Required</p>}
          </div>

          <div>
            <input
              type={eyeOpen ? "text" : "password"}
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="file-input"
            />

            {eyeOpen ? (
              <i
                className="eye-icon"
                style={{ marginTop: "4px", marginLeft: "-30px" }}
                onClick={() => setEyeOpen(false)}
              >
                <IoEyeOutline />
              </i>
            ) : (
              <i
                className="eye-icon"
                style={{ marginTop: "4px", marginLeft: "-30px" }}
                onClick={() => setEyeOpen(true)}
              >
                <IoEyeOffOutline />
              </i>
            )}
            {passwordError && !editpasswordError && (
              <p className="user-required-text">Required</p>
            )}
          </div>
        </div>
        <div className="file">
          {isEdit ? (
            <button className="btn1" onClick={handleUpdate}>
              Update User
            </button>
          ) : (
            <button className="btn1" onClick={handleCreateUser}>
              Create New User
            </button>
          )}
        </div>
      </div>
      <div className="file-box">
        <p className="file-text">All Users</p>
        <div className="user-table">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Eamil</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {userData?.map((item: any) => {
                return (
                  <tr key={item?.id}>
                    <td>{item?.firstName}</td>
                    <td>{item?.lastName}</td>
                    <td>{item?.email}</td>
                    <td>
                      <div className="d-flex">
                        <i
                          className="edit-icon"
                          onClick={() => handleEdit(item)}
                        >
                          <MdModeEdit />
                        </i>
                        <i
                          className="delete-icon"
                          onClick={() => handleDeleteUser(item?.id)}
                        >
                          <MdDelete />
                        </i>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddNewUser;

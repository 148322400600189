import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL


export const getAllFunds = async() => {
    try {
        const response = await axios.get(`${serverUrl}/fund/get-all-funds`,)
        return response.data
    }
    catch(error ) {
      console.error('Error fetching data: ', error);
      throw error;
    };
}

export const getPdfLinks = async(body:any) => {
  try {
      const response = await axios.post(`${serverUrl}/fund/get-pdf-links`, body)
      return response.data
  }
  catch(error ) {
    console.error('Error fetching data: ', error);
    throw error;
  };
}

export const getAllFundsNames = async() => {
  const token = localStorage.getItem("token");
  const headers = {
    'Authorization': `Bearer ${token}` 
  };
  try {
      const response = await axios.get(`${serverUrl}/fund/get-all-funds-name`, {headers} )
      return response.data
  }
  catch(error ) {
    console.error('Error fetching data: ', error);
  };
}

export const getFactSheets = async(isinCode: string) => {
  const token = localStorage.getItem("token");
  const headers = {
    'Authorization': `Bearer ${token}` 
  };
  try {
      const response = await axios.get(`${serverUrl}/fund/get-uploaded-pdf-links/${isinCode}`, {headers} )
      return response.data
  }
  catch(error ) {
    console.error('Error fetching data: ', error);
  };
}

export const getAllISIN = async(code: string) => {
  const token = localStorage.getItem("token");
  const headers = {
    'Authorization': `Bearer ${token}` 
  };
  try {
      const response = await axios.get(`${serverUrl}/fund/get-fund-isin/${code}`, {headers} )
      return response.data
  }
  catch(error ) {
    console.error('Error fetching data: ', error);
    return error
  };
}

export const getAllFundDetail = async(id: string) => {
  try {
      const response = await axios.get(`${serverUrl}/fund/get-funds-details/${id}`,)
      return response.data
  }
  catch(error ) {
    console.error('Error fetching data: ', error);
    
  };
}

export const signin = async (body: any) => {
    try {
      const response = await axios.post(`${serverUrl}/user/login`, body);
      return response
    } catch (error) {
      console.error('Error posting data: ', error);
      return error
    }
};

export const generatOTP = async (body: any) => {
  try {
    const response = await axios.post(`${serverUrl}/user/otp/generate`, body);
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    return error
  }
};

export const verifyOTP = async (body: any) => {
  try {
    const response = await axios.post(`${serverUrl}/user/otp/verify`, body);
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    return error
  }
};

export const forgotPassword = async (body: any) => {
  try {
    const response = await axios.post(`${serverUrl}/user/forgotPassword`, body);
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
  }
};

export const resetPassword = async (body: any) => {
  try {
    const response = await axios.put(`${serverUrl}/user/resetPassword/${body.token}`, body.payload);
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
  }
};

export const uploadDailyCSV = async (body: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.post(`${serverUrl}/fund/upload-daily-funds`, body,{ headers });
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    throw error;
  }
};

export const uploadFundDetailCSV = async (body: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.post(`${serverUrl}/fund/upload-fund-details`, body,{ headers });
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    throw error;
  }
};

export const uploadMonthlyCSV = async (body: any) => {
  try {
    const response = await axios.post(`${serverUrl}/fund/upload-fund-details`, body);
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
  }
};

export const uploadPDF = async (body: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.post(`${serverUrl}/fund/upload-pdf`, body, {headers});
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    throw error;
  }
};


export const uploadPolicyPDF = async (body: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.post(`${serverUrl}/fund/upload-policy-pdf`, body, {headers});
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    throw error;
  }
};

export const GenerateFactsheet = async (body: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.post(`${serverUrl}/fund/generate-fact-sheet`, body, {headers});
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    throw error;
  }
};

export const updateFund = async (body: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.put(`${serverUrl}/fund//update-fund/${body.fundCode}`, body.payload, {headers});
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    throw error;
  }
};

export const getAllUser = async() => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
      const response = await axios.get(`${serverUrl}/user/get-all-users`, {headers})
      return response.data
  }
  catch(error ) {
    console.error('Error fetching data: ', error);
    throw error;
  };
}

export const updateUser = async (body: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.patch(`${serverUrl}/user/update-user`, body, {headers});
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    return error
  }
};

export const createUser = async (body: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.post(`${serverUrl}/user/create-user`, body, {headers});
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    return error
  }
};

export const deleteUser = async (id: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.delete(`${serverUrl}/user/delete-user/${id}`, {headers});
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    return error
  }
};

export const uploadFactsheetPDF = async (body: any) => {
  const token = localStorage.getItem("token");
  const headers= {
    'Authorization': `Bearer ${token}` 
  };
  try {
    const response = await axios.post(`${serverUrl}/fund/upload-fact-sheet`, body, {headers});
    return response
  } catch (error) {
    console.error('Error posting data: ', error);
    throw error;
  }
};

export const testApi = async() => {
  try {
      const response = await axios.get(`http://localhost:8000/hold`,)
      return response.data
  }
  catch(error ) {
    console.error('Error fetching data: ', error);
    throw error;
  };
}

import React, { useEffect, useState } from "react";
import useStore from "../../store";
import { toast } from "react-hot-toast";
import { GenerateFactsheet } from "../../apis/api-endpoint";
import { BsFileArrowDownFill } from "react-icons/bs";
import { getFactSheets } from "../../apis/api-endpoint";

const GenerateFactsheets = ({ fundcode, isinCode }: any) => {
  const { hideLoader, displayLoader } = useStore();
  const [stars, setStars] = useState("");
  const [totalReturn, setTotalReturn] = useState("");
  const [customReturn, setCustomReturn] = useState("");
  const [preservation, setPreservation] = useState("");
  const [factsheets, setFactSheets] = useState([]);
  const [starsError, setStarsError] = useState(false);
  const [totalReturnError, setTotalReturnError] = useState(false);
  const [customreturnError, setCustonreturnError] = useState(false);
  const [preservationError, setPreservationError] = useState(false);

  useEffect(() => {
    const factSheetsData = async () => {
      displayLoader();
      const response = await getFactSheets(isinCode);
      if (response?.status) {
        const factSheetLinks = response?.data?.pdfLinks;
        const factsheetItems = factSheetLinks.filter(
          (item: any) => item.category === "fact-sheet"
        );
        setFactSheets(factsheetItems);

        hideLoader();
      } else if (!response?.status) {
        hideLoader();
      }
    };
    factSheetsData();
  }, [displayLoader, hideLoader, isinCode]);

  useEffect(() => {
    if (stars !== "") setStarsError(false);
    if (totalReturn !== "") setTotalReturnError(false);
    if (customReturn !== "") setCustonreturnError(false);
    if (preservation !== "") setPreservationError(false);
  }, [stars, totalReturn, customReturn, preservation]);

  const handleError = () => {
    if (stars === "") setStarsError(true);
    if (totalReturn === "") setTotalReturnError(true);
    if (customReturn === "") setCustonreturnError(true);
    if (preservation === "") setPreservationError(true);
  };

  const handleGenerateFactsheet = async () => {
    handleError();
    // const timeout = new Promise((_, reject) =>
    //   setTimeout(() => reject(new Error("Request timed out")), 180000)
    // );
    try {
      if (
        stars !== "" &&
        totalReturn !== "" &&
        customReturn !== "" &&
        preservation !== ""
      ) {
        displayLoader();
        const response: any = await GenerateFactsheet({
          code: fundcode,
          stars: Number(stars),
          totalReturn: Number(totalReturn),
          customReturn: Number(customReturn),
          preservation: Number(preservation),
        });

        // hideLoader();
        if (response?.status) {
          toast.success(response?.data.msg);
          setStars("");
          setTotalReturn("");
          setCustomReturn("");
          setPreservation("");
          hideLoader();
          const factSheetsData = async () => {
            displayLoader();
            const response = await getFactSheets(isinCode);
            if (response?.status) {
              const factSheetLinks = response?.data?.pdfLinks;
              const factsheetItems = factSheetLinks.filter(
                (item: any) => item.category === "fact-sheet"
              );
              setFactSheets(factsheetItems);

              hideLoader();
            } else if (!response?.status) {
              hideLoader();
            }
          };
          factSheetsData();
        } else {
          // Handle case where response is not successful but no error thrown
          toast.error("An error occurred while generating the Factsheet.");
        }
      }
    } catch (error) {
      hideLoader();
      toast.error("An error occurred while generating the Factsheet.");
    }
  };

  const handleStarsChange = (e: any) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 5)) {
      setStars(value);
    }
  };

  function handleDownloadFactsheet(factsheetLink: string, name: string) {
    fetch(factsheetLink)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to download factsheet");
        }
        return response.blob(); // Convert response to a Blob
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob); // Create a Blob URL
        const link = document.createElement("a");
        link.href = url;
        link.download = name || "factsheet.pdf"; // Specify the file name
        link.click();
        window.URL.revokeObjectURL(url); // Clean up the Blob URL
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  }

  return (
    <>
      <div className="file">
        <div>
          <h6>Morningstar:</h6>
          <div className=" mb-3">
            <input
              type="number"
              placeholder="Enter Stars"
              value={stars}
              onChange={handleStarsChange}
              className="file-input"
            />
            {starsError && <p className="user-required-text">Required</p>}
          </div>
        </div>
      </div>
      <div className="file">
        <div>
          <h6>LSEG:</h6>
          <div className=" d-flex gap-2">
            <div>
              <input
                type="number"
                placeholder="Enter Total Return"
                value={totalReturn}
                onChange={(e) => setTotalReturn(e.target.value)}
                className="file-input"
              />
              {totalReturnError && (
                <p className="user-required-text">Required</p>
              )}
            </div>
            <div>
              <input
                type="number"
                placeholder="Enter Consistent Return"
                value={customReturn}
                onChange={(e) => setCustomReturn(e.target.value)}
                className="file-input"
              />
              {customreturnError && (
                <p className="user-required-text">Required</p>
              )}
            </div>
            <div>
              <input
                type="number"
                placeholder="Enter Preservation"
                value={preservation}
                onChange={(e) => setPreservation(e.target.value)}
                className="file-input"
              />
              {preservationError && (
                <p className="user-required-text">Required</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="file">
        <button className="btn1" onClick={handleGenerateFactsheet}>
          Generate Factsheet
        </button>
      </div>
      {factsheets.length > 0 && (
        <div className="file mt-4">
          <div>
            <h6>Download Factsheet:</h6>
            <div className=" d-flex gap-3">
              {factsheets.length > 0 &&
                factsheets?.map((item: any, index: any) => {
                  return (
                    <div
                      className="download-factsheet"
                      key={index}
                      onClick={() =>
                        handleDownloadFactsheet(item.link, item.name)
                      }
                    >
                      <BsFileArrowDownFill
                        style={{ color: "darkgray", fontSize: "22px" }}
                      />
                      <p className="download-factsheet-text mb-0">
                        {item?.type}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GenerateFactsheets;
